
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../services/api';
import { PayInResultData, PayInV2CustomerDataIncomplete } from '../../types/payin';
import { useLayoutInfo } from '../../contexts/GlobalContext';
import PaymentFormVersionA, { FormData } from '../../components/layout/a/PaymentFormVersionA';
import LoadingFull from '../../components/common/LoadingFull';
import Breadcrumb from "../../components/common/Breadcrumb";

const Wrapper = styled.div`
`;

const PaymentFormV2: React.FC = () => {
  const { guid, hash } = useParams();
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<PayInResultData>();
  const navigate = useNavigate();
  const { info: merchantInfo, setMerchantInfo } = useLayoutInfo();
  const [userData, setUserData] = useState<PayInV2CustomerDataIncomplete>();

  useEffect(() => {
    setFormSubmitting(false);
    setLoading(true);

    api.post<PayInResultData>(`/v3/charge/${guid}/qrcode`, {}, {
      headers: {
        'x-hash': hash || ''
      }
    })
      .then((res) => {
        setMerchantInfo({
          logo: res.data.accountBrandImage,
          name: res.data.accountName
        });
        console.info('merchant info', res.data);

        navigate(`/v2/${guid}/${hash}/qr`, {
          state: res.data,
          replace: true
        });

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        if (err.response.status == 400) {

          if (err.response.data.error != undefined) {
            navigate(`/v2/${guid}/${hash}/error`, {
              state: err.response.data,
              replace: true
            });
          }

          const response = err.response.data as PayInV2CustomerDataIncomplete;

          setData(response.details);
          setMerchantInfo({
            logo: response.details.accountBrandImage,
            name: response.details.accountName
          });

          if (response.data) {
            setUserData(response);
          }
        }
      });

  }, []);

  function submitForm(data: FormData) {
    api.post<PayInResultData>(`/v3/charge/${guid}/qrcode`, { ...data, customerProvidedName: data.customerName }, {
      headers: {
        'x-hash': hash || ''
      }
    }).then((res) => {
      navigate(`/v2/${guid}/${hash}/qr`, {
        state: res.data,
        replace: true
      });
      setMerchantInfo({
        logo: res.data.accountBrandImage,
        name: res.data.accountName
      });
    }).catch(() => {
      navigate('/error');
      setLoading(false);
    });
  }

  return <>
    <Breadcrumb currentIndex={1} />
    <div className="container">
      <Wrapper>

        {loading ? <LoadingFull /> : undefined}

        {!loading ? <PaymentFormVersionA
          onSubmitForm={(formData: FormData) => {
            submitForm(formData);
            // console.info(data);
          }}
          isSubmitting={isFormSubmitting}
          formData={{
            customerName: userData?.data?.customerProvidedName,
            customerEmail: userData?.data?.customerEmail,
            customerDocument: userData?.data.customerDocument,
          }}
          paymentParams={{
            paymentDescription: data?.paymentDescriptionInfo,
            merchantName: merchantInfo?.name || '',
            tax: '51423',
            shipping: '12',
            total: String(data?.amount)
          }} /> : undefined}
      </Wrapper>
    </div>
  </>;
};

export default PaymentFormV2;
